import React, { useState } from "react";
import { DropzoneArea } from "material-ui-dropzone";

const DropzoneContainer = ({ handleFileObject }) => {
  const [fileObject, setFileObject] = useState(null);

  const deleteFileObj = () => {
    setFileObject(null);
  };

  return (
    <DropzoneArea
      acceptedFiles={["image/*"]}
      dropzoneText={"Drag and drop an image here or click"}
      onChange={(files) => handleFileObject(files)}
      fileObject={fileObject}
      filesLimit={1}
      onAdd={(newFileObj) => {
        setFileObject(newFileObj);
      }}
      onDelete={() => {
        deleteFileObj();
      }}
    />
  );
};

export default DropzoneContainer;
