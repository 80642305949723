import React, { useState, useEffect } from "react";
import DragAndDrop from "../../DragAndDrop/DragAndDrop";
import { withFirebase } from "../../Firebase/context";
import * as CONSTANTS from "../../Constants/constants";
import * as FILE_TYPES from "../../Constants/fileTypes";
import Item from "./Item";
import ImagePreview from "./ImagePreview";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormGroup,
  FilledInput,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  OutlinedInput,
  TextField,
  MenuItem,
  Grid,
  FormLabel,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  Box
} from "@material-ui/core";
import TransitionsModal from "../../Modals/TransitionsModal";

const UploadPhotoContainer = ({ firebase }) => {
  const [files, setFiles] = useState([]);
  const [currentImagePreviewSrc, setCurrentImagePreviewSrc] = useState(null);

  //TODO: Handle file types that aren't images.
  const handleFileInput = ({ target: { files } }) => setFiles(files);

  /**
   * Whenever files are added or removed, clear the input field.
   * This is to prevent the input field
   */
  useEffect(() => {
    const fileField = document.querySelector('input[name="filefield"]');
    fileField && fileField.form.reset();
  }, [files]);
  // TODO: ERROR HANDLING TO TELL USERS THE FILE TYPES ALLOWED.
  /**
   *
   * @param {File} droppedFile
   * This helper method First checks if the file is the correct image type.
   * If image is the right type, check state for if that photo already exists.
   * Returns a boolean value which represents whether or not the file passes validations.
   */
  const validateFiles = droppedFiles => {
    const nextFiles = [];

    const validateFile = droppedFile => {
      const fileName = droppedFile.name;
      const fileType = droppedFile.type;
      const validateFileTypes = fileType => {
        switch (fileType) {
          case FILE_TYPES.JPEG:
            return true;
          case FILE_TYPES.PNG:
            return true;
          case FILE_TYPES.JPG:
            return true;
          default:
            return false;
        }
      };
      if (validateFileTypes(fileType)) {
        for (let i = 0; i < files.length; i++) {
          if (files[i].name === fileName) return false;
        }
        return true;
      }
      return false;
    };

    for (let i = 0; i < droppedFiles.length; i++) {
      const droppedFile = droppedFiles[i];
      if (!droppedFile.name) return;
      if (validateFile(droppedFile)) {
        nextFiles.push(droppedFile);
      }
    }

    setFiles([...files, ...nextFiles]);
  };

  /**
   * Clears files out of the input field on files state change.
   */

  const handleRemoveFile = fileName => {
    const updatedFiles = files.filter(file => file.name !== fileName);
    setFiles(updatedFiles);
  };

  const handleChooseFiles = ({ target: { files } }) => {
    validateFiles(files);
  };

  const handleDrop = droppedFiles => {
    validateFiles(droppedFiles);
  };

  const handleImagePreview = image => {};

  const handleSubmit = e => {
    let prevFiles = files;
    for (let i = 0; i < prevFiles.length; i++) {
      const file = prevFiles[i];

      const metadata = {
        contentType: file.type
      };

      // If the file exists in storage, warn the user about it and don't upload.
      const onResolve = foundURL => {
        console.log("File already exists");
      };

      // If file doesn't exist in storage, upload it.
      const onReject = () => {
        const uploadTask = firebase.storageRef
          .child("images/storage/" + file.name)
          .put(file, metadata);
        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
          "state_changed", // or 'state_changed'
          snapshot => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
            switch (snapshot.state) {
              case CONSTANTS.PAUSED: // or 'paused'
                console.log("Upload is paused");
                break;
              case CONSTANTS.RUNNING: // or 'running'
                console.log("Upload is running");
                break;
            }
          },
          error => {
            //TODO: More advanced error handling
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case "storage/unauthorized":
                // User doesn't have permission to access the object
                break;

              case "storage/canceled":
                // User canceled the upload
                break;
              case "storage/unknown":
                // Unkinown error occurred, inspect error.serverResponse
                break;
            }
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            const uploadedFileName = uploadTask.snapshot.ref.name;
            uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
              console.log("File available at", downloadURL);
              prevFiles = prevFiles.filter(
                currentFile => currentFile.name !== uploadedFileName
              );
              setFiles(prevFiles);
            });
          }
        );
      };

      firebase.storageRef
        .child("images/storage/" + file.name)
        .getDownloadURL()
        .then(onResolve, onReject);
    }
    e.preventDefault();
  };

  return (
    <Box>
      <DragAndDrop
        handleDrop={handleDrop}
        handleChooseFiles={handleChooseFiles}
        handleSubmit={handleSubmit}
      >
        {files.map((file, i) => (
          <Item
            setCurrentImagePreviewSrc={setCurrentImagePreviewSrc}
            file={file}
            key={i}
            handleRemoveFile={handleRemoveFile}
          />
        ))}
      </DragAndDrop>
      {currentImagePreviewSrc && (
        <ImagePreview
          setCurrentImagePreviewSrc={setCurrentImagePreviewSrc}
          currentImagePreviewSrc={currentImagePreviewSrc}
        />
      )}
    </Box>
  );
};

export default withFirebase(UploadPhotoContainer);
