import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import 'fast-text-encoding/text';
import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App/App";
import FirebaseContext from "./components/Firebase/context";
import Firebase from "./components/Firebase/firebase";
import * as serviceWorker from "./serviceWorker";
import "./index.scss";
import "./sass/main.scss";


ReactDOM.render(
  <React.StrictMode>
    <FirebaseContext.Provider value={new Firebase()}>
      <App />
    </FirebaseContext.Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
