import * as CATEGORIES from "./categories";
const categoriesList = [
  {
    description: "Kits containing your everyday essentials",
    id: 1,
    name: CATEGORIES.HOUSING_KITS,
    image: require("../icons/cleaning.png")
  },
  {
    description: "Affordable good quality footwear.",
    id: 3,
    name: CATEGORIES.FOOTWEAR,
    image: require("../icons/shoe-1.png")
  },
  {
    description:
      "Long sleeve and short sleeve t-shirts, sweaters, and hoodies.",
    id: 6,
    name: CATEGORIES.SHIRTS,
    image: require("../icons/t-shirt-3.png")
  },
  {
    description: "All types of work pants, jeans, sweatpants, and shorts.",
    id: 7,
    name: CATEGORIES.PANTS,
    image: require("../icons/jeans.png")
  },
  {
    description: "A variety of undergarments.",
    id: 4,
    name: CATEGORIES.UNDERGARMENTS,
    image: require("../icons/sock.png")
  },
  {
    description: "Sleepwear items such as pajamas and robes",
    id: 8,
    name: CATEGORIES.SLEEPWEAR,
    image: require("../icons/housecoat.png")
  },
  {
    description: "Health and beauty care items.",
    id: 10,
    name: CATEGORIES.PERSONAL_CARE,
    image: require("../icons/deodorant.png")
  },
  {
    description: "All the coats and jackets you can think of. ",
    id: 5,
    name: CATEGORIES.OUTERWEAR,
    image: require("../icons/jacket-1.png")
  },
  {
    description: "Everything you need to sleep comfortably.",
    id: 17,
    name: CATEGORIES.BEDDING,
    image: require("../icons/pillow.png")
  },
  {
    description: "A variety of towels, from wash clothes to beach towels.",
    id: 11,
    name: CATEGORIES.TOWELS,
    image: require("../icons/towel.png")
  },
  {
    description: "Garments and all else for the little ones.",
    id: 9,
    name: CATEGORIES.BABIES,
    image: require("../icons/onesie.png")
  },
  {
    description: "Items such as belts, ties, and work gloves.",
    id: 16,
    name: CATEGORIES.ACCESSORIES,
    image: require("../icons/belt.png")
  },
  {
    description: "Dinnerware, cleaning supplies, and furnishings.",
    id: 12,
    name: CATEGORIES.HOUSEWARES,
    image: require("../icons/wrap.png")
  },
  {
    description: "Everything you need for carrying things.",
    id: 2,
    name: CATEGORIES.BAGS,
    image: require("../icons/backpack.png")
  },
  {
    description: "A selection of items that rotate per season.",
    id: 15,
    name: CATEGORIES.SEASONAL,
    image: require("../icons/weather.png")
  },
  {
    description:
      "Stay dry with our selection of umbrellas, ponchos, and raincoats.",
    id: 14,
    name: CATEGORIES.RAINWEAR,
    image: require("../icons/umbrella.png")
  },
  {
    description:
      "Supplies such as food service, paper products, and plastic bags.",
    id: 18,
    name: CATEGORIES.INSTITUTIONAL_SUPPLIES,
    image: require("../icons/bin.png")
  },
  {
    description: "Get ready for summer with our affordable swimwear.",
    id: 13,
    name: CATEGORIES.SWIMWEAR,
    image: require("../icons/swimsuit.png")
  }
];

export default categoriesList;
