import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

const useStyles = makeStyles((theme) => ({
  edit: {
    cursor: "pointer",
    textAlign: "center",
  },
  icon: {
    color: "#292f36ff",
  },
}));

const DeleteItemDialog = ({
  params: { value },
  handleSetLoading,
  handleRemoveDeletedItem,
  setError,
  firebase,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteItem = () => {
    handleSetLoading(true);
    firebase.db
      .ref(`items/${value.categoryId}/${value.id}`)
      .remove()
      .then(() => {
        const itemStorageRef = firebase.storageRef.child(
          `items/${value.categoryId}/${value.id}.png`
        );
        if (itemStorageRef) {
          itemStorageRef.delete().then(() => {
            handleRemoveDeletedItem(value.id);
            handleSetLoading(false);
            handleClose();
          });
        } else {
          handleRemoveDeletedItem(value.id);
          handleSetLoading(false);
          handleClose();
        }
      })
      .catch((e) => {
        setError(e);
        handleSetLoading(false);
        handleClose();
      });
  };

  return (
    <div>
      <div className={classes.edit} onClick={handleOpen}>
        <DeleteForeverIcon variant="contained" className={classes.icon} />
        Delete Item
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Delete Item</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete <b>{value.itemName}</b>?
          </DialogContentText>
          <Button
            onClick={handleDeleteItem}
            style={{ float: "left" }}
            variant="contained"
            color="secondary"
          >
            Delete
          </Button>
          <Button
            onClick={handleClose}
            style={{ float: "right" }}
            variant="contained"
            color="primary"
          >
            Close
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DeleteItemDialog;
