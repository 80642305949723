import app from "firebase/app";
import * as ROLES from "../Constants/roles";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";

/**
 * Normally exposing API keys are bad, but for firebase it is fine.
 */
const firebaseConfig = {
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  authDomain: "bert-drobbin-company.firebaseapp.com",
  databaseURL: "https://bert-drobbin-company.firebaseio.com",
  projectId: "bert-drobbin-company",
  storageBucket: "bert-drobbin-company.appspot.com",
  messagingSenderId: "814804319262",
  appId: "1:814804319262:web:6a138a8398ab36e6ee420f",
  measurementId: "G-D6ZTH5NHKT",
};

class Firebase {
  constructor() {
    app.initializeApp(firebaseConfig);

    this.auth = app.auth();
    this.db = app.database();
    this.storage = app.storage();
    this.storageRef = this.storage.ref();
  }

  // *** Auth API ***

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        this.user(authUser.uid)
          .once("value")
          .then((snapshot) => {
            const dbUser = snapshot.val();
            const localStorageAuthUser = JSON.parse(
              localStorage.getItem("authUser")
            );

            // default empty roles
            if (!dbUser.role) {
              dbUser.role = ROLES.CUSTOMER;
            }
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser,
            };
            // REFACTOR THIS
            if (
              localStorageAuthUser &&
              localStorageAuthUser.uid === authUser.uid
            ) {
              next(authUser);
            } else if (!localStorageAuthUser) {
              next(authUser);
            }
          });
      } else {
        fallback();
      }
    });

  createUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  signInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  signOut = () => this.auth.signOut();

  passwordReset = (email) => this.auth.sendPasswordResetEmail(email);

  passwordUpdate = (password) => this.auth.currentUser.updatePassword(password);

  // *** User API ***

  user = (uid) => this.db.ref(`users/${uid}`);

  users = () => this.db.ref("users");
}

export default Firebase;
