import React, { useState, useEffect, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  Button,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { withFirebase } from "../../Firebase/context";
import categoriesList from "../../Constants/categoriesList";
import AddItemForm from "./AddItemForm";
import EditItems from "./EditItems";
import MenuGroupList from "./MenuGroupList";
import * as COLORS from "../../Constants/colors";
import { fade } from "@material-ui/core/styles/colorManipulator";

const editIcon = require("../../icons/edit.png");

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "192px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      height: "320px",
    },
  },
  dataGrid: {
    width: 450,
    height: 500,
  },
  edit: {
    cursor: "pointer",
    textAlign: "center",
    padding: 2,
  },
  icon: {
    color: "#292f36ff",
  },
  cardMedia: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "80px",
      objectFit: "scale-down",
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      height: 200,
      objectFit: "scale-down",
    },
  },
  cardMediaWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    padding: "6px",
  },
  cardContent: {
    padding: "6px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "80px",
      objectFit: "scale-down",
      textAlign: "center",
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      height: "100px",
      objectFit: "scale-down",
      textAlign: "center",
    },
  },
  card: {
    "&:hover": {
      backgroundColor: fade(COLORS.BLUE_BELL, 0.2),
    },
  },
  backButton: {
    position: "absolute",
    bottom: 0,
    left: 0,
    padding: "5px",
    margin: "5px",
  },
}));

const CategoryList = ({ firebase }) => {
  const classes = useStyles();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [categoryData, setCategoryData] = useState({});
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns = [
    { field: "id", hide: true },
    { field: "col1", headerName: "Category Name", width: 180 },
    {
      field: "col2",
      headerName: "Items",
      width: 120,
      renderCell: (params) => (
        <EditItems
          key={`view-category-${params.value.name}`}
          category={params.value}
        />
      ),
    },
    {
      field: "col3",
      headerName: "Add Item",
      width: 180,
      renderCell: (params) => (
        <AddItemForm
          key={`add-item-${params.value.name}`}
          category={params.value}
        />
      ),
    },
  ];

  return (
    <div>
      <Card className={classes.card} key={"category-list-button"}>
        <CardActionArea onClick={handleClickOpen}>
          <div className={classes.cardMediaWrapper}>
            <CardMedia
              component="img"
              className={classes.cardMedia}
              alt={"Edit Catalog"}
              title={"Edit Catalog"}
              src={editIcon}
            />
          </div>
          <CardContent className={classes.cardContent}>
            <Typography gutterBottom variant="h5" component="h2">
              Edit Catalog
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <div style={{ maxWidth: 500 }}>
          <DialogTitle id="form-dialog-title">Categories</DialogTitle>
          <DialogContent>
            <DialogContentText>
              If you wish to <b>add</b>, <b>delete</b> or <b>edit</b> a{" "}
              <b>product item</b> from the <b>catalog</b>, press the <b>view</b>{" "}
              button that is alongside the <b>category</b> name.
            </DialogContentText>
            <div className={classes.dataGrid}>
              <DataGrid
                hideFooterPagination={true}
                hideFooterSelectedRowCount={true}
                loading={loading}
                error={error && error.message}
                columns={columns}
                rows={categoriesList.map((category) => {
                  return {
                    id: category.id,
                    col1:
                      category.name.charAt(0).toUpperCase() +
                      category.name.slice(1),
                    col2: category,
                    col3: category,
                  };
                })}
              />
              <Grid item>
                <Button
                  onClick={handleClose}
                  className={classes.backButton}
                  color="primary"
                >
                  Back
                </Button>
              </Grid>
            </div>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
};

export default withFirebase(CategoryList);
