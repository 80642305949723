import React from "react";
import { ZoomOut } from "react-feather";

const ImagePreview = ({
  setCurrentImagePreviewSrc,
  currentImagePreviewSrc
}) => {
  return (
    <div
      onClick={() => setCurrentImagePreviewSrc(null)}
      className="image-preview"
    >
      <img src={currentImagePreviewSrc} />
      <ZoomOut className="zoom-out-icon" />
    </div>
  );
};

export default ImagePreview;
