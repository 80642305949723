import React from "react";
import { Typography, Grid, Container } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import withAuthorization from "../Session/withAuthorization";
import * as ROUTES from "../Constants/routes";
import { Link } from "react-router-dom";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 20,
  },
  pad: {
    height: "64px",
  },
  logo: {
    // height: "100%",
    // width: "100%",
    maxHeight: "256px",
    maxWidth: "256px",
    minHeight: "128px",
    minWidth: "128px",
  },
}));

const HomePage = () => {
  const classes = useStyles();
  return (
    <Container className={classes.root} maxWidth="md">
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={12}>
          <img
            className={classes.logo}
            src={process.env.PUBLIC_URL + "/BDCLogo.svg"}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={12} sm={6}>
          <Typography align="center">
            At the Bert Drobbin Company, we take great pride in serving the
            youth facility market, as well as homeless shelters, residential
            housing facilities, NYC ACS, secured and non secured detention City,
            State, and, Federal run programs.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography align="center">
            For over 40 years, The Bert Drobbin Company has been happy to serve
            the tri-state area and beyond, supplying our customers with
            clothing, footwear, personal care, bedding, welcome kits, household
            supplies, home furnishings hardware, and appliances.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography align="center">
            We are a full service source, and with our professionally trained
            staff, we will work along with residents, counselors, and
            administrators to assist in fulfilling all your needs, and wants.
            Daily, our trucks service the tri state area and beyond.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography align="center">
            This website serves as a catalog for our goods and services. All
            sales are done by inquiry only. If you are interested in doing
            business with us please{" "}
            <Link to={ROUTES.CONTACT_US}>contact us</Link>. To see what goods we
            have to offer, check out our{" "}
            <Link to={ROUTES.CATALOG}>catalog</Link>.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default HomePage;
