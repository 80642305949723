import React from "react";
import PropTypes from "prop-types";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import {
  withStyles,
  makeStyles,
  responsiveFontSizes,
  ThemeProvider,
  createMuiTheme,
  usetheme,
} from "@material-ui/core/styles";
import {
  AppBar,
  Container,
  Toolbar,
  Typography,
  CssBaseline,
  useScrollTrigger,
  Box,
  useMediaQuery,
  Grid,
} from "@material-ui/core";
import SignInPage from "../Auth/SignIn/signInPage";
import AdminPage from "../Admin/AdminPage";
import * as ROUTES from "../Constants/routes";
import * as ORGANIZATIONS from "../Constants/organizations";
import withAuthentication from "../Session/withAuthentication";
import CatalogPage from "../Catalog/CatalogPage";
import HomePage from "../Home/HomePage";
import ErrorPage from "../Errors/ErrorPage";
import CategoryPage from "../Catalog/CategoryPage";
import Footer from "../Footer/Footer";
import Navigation from "../Navigation/Navigation";
import ContactPage from "../ContactPage/ContactPage";

const theme = createMuiTheme({
  typography: {
    fontFamily: ["Open Sans", "sans-serif"].join(","),
  },
});
// TODO: Make this mobile friendly or friendly for all screen sizes

// These breakpoints are to help with screen sizes
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  pad: {
    height: "64px",
  },
}));

const ElevationScroll = (props) => {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });
  return React.cloneElement(children, {
    elevation: trigger ? 7 : 0,
  });
};

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const ElevateAppBar = (props) => {
  return (
    <React.Fragment>
      <CssBaseline />
      <ElevationScroll {...props}></ElevationScroll>
    </React.Fragment>
  );
};

const App = () => {
  const classes = useStyles();

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <ElevateAppBar>
          <Navigation />
        </ElevateAppBar>
        <div className={classes.pad} />
        <Switch>
          <Route exact path={"/"}>
            <Redirect to={ROUTES.HOME} />
          </Route>
          <Route exact path={ROUTES.ADMIN} component={AdminPage} />
          <Route path={ROUTES.CONTACT_US} component={ContactPage} />
          <Route path={ROUTES.SIGN_IN} component={SignInPage} />
          <Route exact path={ROUTES.HOME} component={HomePage} />
          <Route exact path={ROUTES.CATALOG} component={CatalogPage} />
          <Route
            exact
            path={`${ROUTES.CATALOG}/:categoryName`}
            component={CategoryPage}
          />
          <Route path={ROUTES.ERROR404} component={ErrorPage} />
          <Route>
            <Redirect to={ROUTES.ERROR404} />
          </Route>
        </Switch>
        <Footer />
      </ThemeProvider>
    </Router>
  );
};

export default withAuthentication(App);
