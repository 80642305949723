import React, { useState, useEffect, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { withFirebase } from "../../Firebase/context";
import EditItemForm from "./EditItemForm";
import DeleteItemDialog from "./DeleteItemDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    backgroundColor: theme.palette.background.paper,
  },
  dataGridWrapper: {
    height: 500,
    width: 570,
  },
  gridList: {
    width: 800,
    height: 450,
  },
  edit: {
    cursor: "pointer",
    textAlign: "center",
  },
  icon: {
    color: "#292f36ff",
  },
  backButton: {
    position: "absolute",
    bottom: 0,
    left: 0,
    padding: "5px",
    margin: "5px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const EditItems = ({ firebase, category }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [items, setItems] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    return () => {
      setInitialState();
    };
  }, []);

  const setInitialState = () => {
    setLoading(false);
    setError(null);
    setItems([]);
    setOpen(false);
  };

  const handleClickOpen = () => {
    handleFetchData();
    setOpen(true);
  };

  const handleClose = () => {
    setInitialState();
  };

  const handleSetLoading = (value) => {
    setLoading(value);
  };

  const handleRemoveDeletedItem = (id) => {
    // setItems(items.filter((item) => item.id !== id));
    handleFetchData();
  };

  const handleFetchData = () => {
    setLoading(true);
    firebase.db
      .ref(`items/${category.id}`)
      .once("value", (snapshot) => {
        const filteredItems = [];
        snapshot.forEach((item) => {
          filteredItems.push(item.val());
        });
        setItems(filteredItems);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
    // firebase.db
    //   .ref("items")
    //   .once("value", (snapshot) => {
    //     const filteredItems = [];
    //     snapshot.forEach((item) => {
    //       const itemData = item.val();
    //       if (itemData.categoryId === category.id) {
    //         filteredItems.push(itemData);
    //       }
    //     });
    //     setItems(filteredItems);
    //     setLoading(false);
    //   })
    //   .catch((error) => {
    //     setError(error);
    //     setLoading(false);
    //   });
  };

  const columns = [
    { field: "id", hide: true },
    {
      field: "col1",
      headerName: "Item Name",
      width: 150,
    },
    {
      field: "col2",
      headerName: "Edit",
      width: 130,
      renderCell: (params) => (
        <EditItemForm
          key={`edit-${params.value.id}`}
          firebase={firebase}
          params={params}
          setLoading={setLoading}
          setError={setError}
          handleFetchData={handleFetchData}
        />
      ),
    },
    {
      field: "col3",
      headerName: "Delete",
      width: 140,
      renderCell: (params) => (
        <div key={`delete-${params.value.id}`}>
          <DeleteItemDialog
            params={params}
            handleSetLoading={handleSetLoading}
            firebase={firebase}
            handleRemoveDeletedItem={handleRemoveDeletedItem}
            setError={setError}
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <Button onClick={handleClickOpen} color="default" variant="outlined">
        VIEW
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Edit Items</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Press <b>edit</b> to make a change to an existing item or{" "}
            <b>delete</b> to remove an existing item from the <b>catalog</b>.
          </DialogContentText>
          <div className={classes.dataGridWrapper}>
            <DataGrid
              onColumnHeaderClick={null}
              disableColumnSelector={true}
              hideFooterPagination={true}
              loading={loading}
              hideFooterSelectedRowCount={true}
              error={error && error.message}
              columns={columns}
              rows={items.map((item) => {
                return {
                  id: item.id,
                  col1: item.itemName,
                  col2: item,
                  col3: item,
                };
              })}
            />
            <Grid item>
              <Button
                onClick={handleClose}
                className={classes.backButton}
                color="primary"
              >
                Back
              </Button>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default withFirebase(EditItems);
