import React, { useState, useEffect } from "react";
import { Link, withRouter, Redirect } from "react-router-dom";
import * as ROUTES from "../Constants/routes";
import * as ROLES from "../Constants/roles";
import AuthUserContext from "../Session/context";
import {
  withStyles,
  makeStyles,
  useTheme,
  emphasize,
} from "@material-ui/core/styles";
import {
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
  Toolbar,
  useMediaQuery,
  Menu,
  MenuItem,
  Button,
  IconButton,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  Breadcrumbs,
  Chip,
} from "@material-ui/core";
import { withFirebase } from "../Firebase/context";
import MenuIcon from "@material-ui/icons/Menu";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CategoryMenuList from "../Catalog/CategoryMenuList";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip);

const NavigationBreadcrumb = ({
  navigationLabel,
  breadcrumbType,
  handleOnClick,
  icon,
  categories,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  if (breadcrumbType === "link") {
    return (
      <div className={classes.root}>
        <div>
          <StyledBreadcrumb
            icon={icon ? icon : <div />}
            label={navigationLabel}
            onClick={handleOnClick}
          />
        </div>
      </div>
    );
  }

  if (breadcrumbType === "menulist")
    return (
      <div className={classes.root}>
        <div>
          <StyledBreadcrumb
            label={navigationLabel}
            deleteIcon={<ExpandMoreIcon />}
            onClick={handleToggle}
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            onDelete={handleToggle}
          />
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                    >
                      <CategoryMenuList
                        handleClose={handleClose}
                        categories={categories}
                      />
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </div>
    );
};

export default NavigationBreadcrumb;
