import React, { useState } from "react";
import * as CONSTANTS from "../Constants/constants";
import { Link } from "react-router-dom";
import * as ROUTES from "../Constants/routes";
import * as ROLES from "../Constants/roles";
import { withFirebase } from "../Firebase/context";
import { withRouter } from "react-router-dom";
import TransitionsModal from "../Modals/TransitionsModal";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
} from "@material-ui/core";
import * as COLORS from "../Constants/colors";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { makeStyles } from "@material-ui/core/styles";
import CustomAlert from "../Alert/CustomAlert";

const createUserIcon = require("../icons/createusericon.png");

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "192px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      height: "320px",
    },
  },
  cardMedia: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "80px",
      objectFit: "scale-down",
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      height: 200,
      objectFit: "scale-down",
    },
  },
  cardMediaWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    padding: "6px",
  },
  cardContent: {
    padding: "6px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "80px",
      objectFit: "scale-down",
      textAlign: "center",
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      height: "100px",
      objectFit: "scale-down",
      textAlign: "center",
    },
  },
  card: {
    "&:hover": {
      backgroundColor: fade(COLORS.BLUE_BELL, 0.2),
    },
  },
}));

const CreateUserForm = ({ firebase, history }) => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [passwordOne, setPasswordOne] = useState("");
  const [passwordTwo, setPasswordTwo] = useState("");
  const [role, setRole] = useState(ROLES.ADMIN);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [severityType, setSeverityType] = useState("");
  const [message, setMessage] = useState("");

  const classes = useStyles();

  const setInitialState = () => {
    setUsername("");
    setEmail("");
    setRole(ROLES.ADMIN);
    setPasswordOne("");
    setPasswordTwo("");
    setError(null);
    setSeverityType("");
    setMessage("");
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setInitialState();
    setOpen(false);
  };

  const handleUsername = (e) => {
    setUsername(e.target.value);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordOne = (e) => {
    setPasswordOne(e.target.value);
  };

  const handlePasswordTwo = (e) => {
    setPasswordTwo(e.target.value);
  };

  const handleSubmit = (e) => {
    firebase
      .createUserWithEmailAndPassword(email, passwordOne)
      .then((authUser) => {
        firebase
          .user(authUser.user.uid)
          .set({
            username,
            email,
            role,
          })
          .then(() => {
            setSeverityType("success");
            setMessage("New user successfully created!");
          });
      })
      .catch((err) => {
        setError(err);
        setSeverityType("error");
        setMessage("Error creating new user!");
      });
  };

  const isInvalid =
    passwordOne !== passwordTwo ||
    passwordOne === "" ||
    email === "" ||
    username === "";

  return (
    <div>
      <Card className={classes.card} key={"create-user-button"}>
        <CardActionArea onClick={handleClickOpen}>
          <div className={classes.cardMediaWrapper}>
            <CardMedia
              component="img"
              className={classes.cardMedia}
              alt={"Create User"}
              src={createUserIcon}
              title={"Create User"}
            />
          </div>
          <CardContent className={classes.cardContent}>
            <Typography gutterBottom variant="h5" component="h2">
              Create new user
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Create new user</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To create a new user with administrator privilages, enter a
            username, email, and password.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="set-username"
            label="Username"
            type="text"
            fullWidth
            value={username}
            onChange={handleUsername}
          />
          <TextField
            margin="dense"
            id="set-email"
            label="Email"
            type="text"
            fullWidth
            value={email}
            onChange={handleEmail}
          />
          <TextField
            margin="dense"
            id="set-password-one"
            label="Password"
            type="password"
            fullWidth
            value={passwordOne}
            onChange={handlePasswordOne}
          />
          <TextField
            margin="dense"
            id="set-password-two"
            label="Confirm password"
            type="password"
            fullWidth
            value={passwordTwo}
            onChange={handlePasswordTwo}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button disabled={isInvalid} onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
        <DialogContent>
          {severityType.length > 0 && (
            <CustomAlert severityType={severityType} message={message} />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default withRouter(withFirebase(CreateUserForm));
