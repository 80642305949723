export const HOUSING_KITS = "Housing Kits";
export const BAGS = "Bags";
export const FOOTWEAR = "Footwear";
export const UNDERGARMENTS = "Undergarments";
export const OUTERWEAR = "Outerwear";
export const SHIRTS = "Shirts";
export const PANTS = "Pants";
export const SLEEPWEAR = "Sleepwear";
export const BABIES = "Babies";
export const PERSONAL_CARE = "Personal Care";
export const HOUSEWARES = "Housewares";
export const TOWELS = "Towels";
export const SWIMWEAR = "Swimwear";
export const RAINWEAR = "Rainwear";
export const SEASONAL = "Seasonal";
export const ACCESSORIES = "Accessories";
export const BEDDING = "Bedding";
export const INSTITUTIONAL_SUPPLIES = "Institutional Supplies";
