import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { withFirebase } from "../Firebase/context";
import { Grid, Container, Typography } from "@material-ui/core";
import Item from "./Item";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import categoriesList from "../Constants/categoriesList";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 20,
  },
}));

const CategoryPage = (props) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    const {
      match: {
        params: { categoryName },
      },
    } = props;
    const categoryId = categoriesList.find(
      (category) => categoryName === category.name
    ).id;
    setLoading(true);
    // props.firebase.db.ref(`items`).once("value", snapshot => {
    //   // Turns an Object containing Objects into an array of Objects, then setting it to state.
    //   const val = Object.values(snapshot.val());
    //   debugger;
    //   // if (snapshot.val()) {
    //   //   setItems(Object.values(snapshot.val()));
    //   // }
    // });

    props.firebase.db
      .ref(`items/${categoryId}`)
      .once("value", (snapshot) => {
        const filteredItems = [];
        snapshot.forEach((item) => {
          const itemData = item.val();
          if (itemData.categoryId === categoryId) {
            filteredItems.push(itemData);
          }
        });
        setItems(filteredItems);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setError(e);
      });

    return () => {};
  }, []);

  if (loading) {
    return (
      <Container className={classes.root} maxWidth="md">
        <Typography variant="h6" align="center">
          Loading...
        </Typography>
      </Container>
    );
  } else if (items.length > 0) {
    return (
      <Container className={classes.root} maxWidth="md">
        <Grid container spacing={3}>
          {items.map((item) => (
            <Grid key={item.id} item xs={12} sm={4}>
              <Item item={item} />
            </Grid>
          ))}
        </Grid>
      </Container>
    );
  } else {
    return (
      <Container className={classes.root} maxWidth="md">
        <Typography variant="h6" align="center">
          There are currently no items to be shown here. Please check again at a
          later time, or contact us.
        </Typography>
      </Container>
    );
  }
};

export default withRouter(withFirebase(CategoryPage));
