import React, { useState, useEffect } from "react";
import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  Typography,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  GridList,
  GridListTile,
  GridListTileBar,
  ListSubheader,
  useMediaQuery
} from "@material-ui/core";

import * as CATEGORIES from "../Constants/categories";
import * as COLORS from "../Constants/colors";
import { fade } from "@material-ui/core/styles/colorManipulator";

const MainCategoryCard = withStyles(theme => ({
  root: {
    flexGrow: 1,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "192px"
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      height: "320px"
    }
  },
  cardMedia: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "80px",
      objectFit: "scale-down"
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      height: 200,
      objectFit: "scale-down"
    }
  },
  cardMediaWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    padding: "6px"
  },
  cardContent: {
    padding: "6px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "80px",
      objectFit: "scale-down",
      textAlign: "center"
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      height: "100px",
      objectFit: "scale-down",
      textAlign: "center"
    }
  },
  card: {
    "&:hover": {
      backgroundColor: fade(COLORS.BLUE_BELL, 0.2)
    }
  }
}))(
  ({
    classes: { card, root, cardMedia, cardMediaWrapper, cardContent },
    category,
    handleOnClick
  }) => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
      <Grid
        className={root}
        item
        xs={category.name === CATEGORIES.HOUSING_KITS ? 12 : 6}
        sm={category.name === CATEGORIES.HOUSING_KITS ? 12 : 4}
        key={category.id}
      >
        <Card raised className={card}>
          <CardActionArea onClick={e => handleOnClick(category)}>
            <div className={cardMediaWrapper}>
              <CardMedia
                component="img"
                className={cardMedia}
                alt={category.name}
                src={category.image}
                title={category.name}
              />
            </div>
            <CardContent className={cardContent}>
              <Typography variant="h6">{category.name}</Typography>
              {!mobile && (
                <Typography gutterBottom variant="caption">
                  {category.description}
                </Typography>
              )}
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    );
  }
);

export default MainCategoryCard;
