import React from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import * as ERRORS from "../Constants/errors";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: 20
  }
}));

const ErrorPage = ({ error = ERRORS.error404 }) => {
  const classes = useStyles();

  return (
    <Container align="center" className={classes.root} maxWidth="md">
      <Typography variant="h4">ERROR {error.code}</Typography>
      <Typography variant={"subtitle1"}>{error.message}</Typography>
    </Container>
  );
};

export default ErrorPage;
