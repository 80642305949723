import React, { useState, useEffect, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DropzoneContainer from "./DropzoneContainer";
import CustomAlert from "../../Alert/CustomAlert";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    backgroundColor: theme.palette.background.paper,
  },
  dataGridWrapper: {
    height: 500,
    width: 570,
  },
  gridList: {
    width: 800,
    height: 450,
  },
  edit: {
    cursor: "pointer",
    textAlign: "center",
  },
  icon: {
    color: "#292f36ff",
  },
  backButton: {
    position: "absolute",
    bottom: 0,
    left: 0,
    padding: "5px",
    margin: "5px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const EditItemForm = ({
  params: { value },
  firebase,
  handleFetchData,
  setError,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [description, setDescription] = useState("");
  const [name, setName] = useState("");
  const [fileObject, setFileObject] = useState(null);
  const [severityType, setSeverityType] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    return () => {
      setInitialState();
    };
  }, []);

  const clearAlertState = () => {
    setSeverityType("");
    setMessage("");
  };

  const setInitialState = () => {
    setName(value.itemName);
    setDescription(value.itemDescription);
    setOpen(true);
  };

  const clearState = () => {
    setName("");
    setDescription("");
    setFileObject(null);
    setSeverityType("");
    setMessage("");
    setLoading(false);
    setOpen(false);
  };

  const handleDescription = (e) => {
    setDescription(e.target.value);
  };

  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleFileObject = (file) => {
    setFileObject(file[0]);
  };

  const handleSubmit = (e) => {
    setLoading(true);
    const itemRef = firebase.db.ref(`items/${value.categoryId}/${value.id}`);

    if (typeof fileObject !== "undefined") {
      const itemStorageRef = firebase.storageRef.child(
        `items/${value.categoryId}/${value.id}.png`
      );
      itemStorageRef
        .put(fileObject)
        .then((snapshot) => {
          const payloadData = {
            itemName: name,
            itemDescription: description,
          };
          itemRef.update(payloadData).then(() => {
            handleFetchData();
            setSeverityType("success");
            setMessage("Successfully updated item!");
            setTimeout(() => clearAlertState(), 3000);
            setLoading(false);
          });
        })
        .catch((error) => {
          setError(error);
          setSeverityType("error");
          setMessage(
            "Failed to edit item! If this persists contact your web developer."
          );
          setTimeout(() => clearAlertState(), 3000);
          setLoading(false);
        });
    } else {
      const payloadData = {
        itemName: name,
        itemDescription: description,
      };
      itemRef
        .update(payloadData)
        .then(() => {
          handleFetchData();
          setSeverityType("success");
          setMessage("Successfully updated item!");
          setTimeout(() => clearAlertState(), 3000);
          setLoading(false);
        })
        .catch((error) => {
          setError(error);
          setSeverityType("error");
          setMessage(
            "Failed to edit item! If this persists contact your web developer."
          );
          setTimeout(() => clearAlertState(), 3000);
          setLoading(false);
        });
    }
  };

  const handleClickOpen = () => {
    setInitialState();
  };

  const handleClose = () => {
    clearState();
  };

  return (
    <div>
      <div className={classes.edit} onClick={handleClickOpen}>
        <EditIcon variant="contained" className={classes.icon} />
        <strong>Edit</strong>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Edit Item Form</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Edit an item's name, description, or image.
          </DialogContentText>
          <TextField
            autoFocus
            multiline
            maxRows={2}
            margin="dense"
            id="edit-item-name"
            label="Item Name"
            type="text"
            fullWidth
            value={name}
            onChange={handleName}
          />
          <TextField
            autoFocus
            multiline
            maxRows={4}
            margin="dense"
            id="edit-item-description"
            label="Item Description"
            type="text"
            fullWidth
            value={description}
            onChange={handleDescription}
          />
          <DropzoneContainer handleFileObject={handleFileObject} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
        <DialogContent>
          {severityType.length > 0 && (
            <CustomAlert severityType={severityType} message={message} />
          )}
        </DialogContent>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>
    </div>
  );
};

export default EditItemForm;
