import React, { useState, useEffect } from "react";
import CategoryItemsList from "./CategoryItemsList";
import CategoryCard from "./CategoryCard";
import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import { withFirebase } from "../Firebase/context";
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Grid,
  Paper,
  Typography,
  Container
} from "@material-ui/core";
import ButtonBase from "@material-ui/core/ButtonBase";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch,
  Redirect,
  withRouter
} from "react-router-dom";
import { KeyboardArrowLeft } from "@material-ui/icons";
import MainCategoryCard from "./MainCategoryCard";
import * as CATEGORIES from "../Constants/categories";
import categoriesList from "../Constants/categoriesList";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: 20
  },
  gridContainer: {
    paddingLeft: "40px",
    paddingRight: "40px"
  }
}));

const CatalogPage = props => {
  const classes = useStyles();
  const [error, setError] = useState(false);

  const handleOnClick = category => {
    // history.push(`${path}/${category.id}/${category.name}`);
    props.location.state = { categoryId: category.id };
    props.history.push(`${props.match.path}/${category.name}`);
  };
  return (
    <Container className={classes.root} maxWidth="md">
      <Grid spacing={3} container>
        {categoriesList.map(category => (
          <MainCategoryCard
            key={category.id}
            category={category}
            handleOnClick={handleOnClick}
            loading={false}
          />
        ))}
      </Grid>
    </Container>
  );
};

export default withRouter(withFirebase(CatalogPage));
