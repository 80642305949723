import React from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 20,
  },
}));

const ContactPage = () => {
  const classes = useStyles();

  return (
    <Container align="center" className={classes.root} maxWidth="md">
      <Typography variant="h4">Bert Drobbin Co.</Typography>
      <Typography variant={"subtitle1"}>
        <p>
          <b>Address</b>
        </p>
        8711 111TH St Richmond Hill, NY, 11418-2314 USA
        <p>
          <b>Contact us</b>
        </p>
        <p>Phone: (718) 847-2010</p>
        <p>Fax: (718) 847-7994</p>
        <p>
          <b>Email</b>
        </p>
        <p>bertdrobbin@hotmail.com</p>
      </Typography>
      {/* 
      <div>
        <iframe
          width="600"
          height="450"
          frameBorder="0"
          style={{ border: 0 }}
          src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
    &q=Bert+Drobbin+Company`}
          allowFullScreen
        />
      </div> */}
    </Container>
  );
};

export default React.memo(ContactPage);
