import React from "react";
import { AppBar, Tabs, Tab, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FullscreenExit } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  root: {
    gridArea: "footer",
    backgroundColor: "#292f36ff",
    color: "#ffffff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
}));

const Footer = props => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Typography>
        Bert Drobbin Co. All rights reserved.
        {/* <br></br>
        This website is still in development. */}
      </Typography>
    </Box>
  );
};

export default Footer;
