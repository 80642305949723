import React, { useState, useEffect } from "react";
import Item from "./Item";
import CategoryCard from "./CategoryCard";
import { makeStyles } from "@material-ui/core/styles";
import { withFirebase } from "../Firebase/context";
import {
  Grid,
} from "@material-ui/core";
import ButtonBase from "@material-ui/core/ButtonBase";
import {
  BrowserRouter as Router,
  withRouter
} from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  gridItem: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "192px"
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      height: "320px"
    }
  }
}));

const CategoryItemsList = ({ firebase, match }) => {
  const classes = useStyles();
  const [items, setItems] = useState({});
  // const [toggle, setToggle] = useState(false);
  // const [selectedItem, setSelectedItem] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const {
    params: { categoryId }
  } = match;

  // Refactor this to handle setItems in a more clean fashion.
  // A problem Iv'e been having is a routes content rendering inside of other routes.
  // My small fix was to just clear setItems everytime this component loads.
  // But why is the state of items being saved even when the component re-renders?
  useEffect(() => {
    setLoading(true);
    setItems({});
    const categoryRef = firebase.db
      .ref("/categories/" + categoryId)
      .once("value", snapshot => {
        const category = snapshot.val();
        if (category.items) {
          setItems(category.items);
        }
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        setError(e);
      });

    return () => {};
  }, [categoryId]);

  if (error) {
    return (
      <div>
        <h1>ERROR 404 </h1>
        {error.message}
      </div>
    );
  }

  if (loading) {
    return <h1>LOADING...</h1>;
  } else
    return (
      <div className={classes.root}>
        <Grid container spacing={3}>
          {Object.keys(items).length > 0 &&
            Object.keys(items).map(key => {
              return (
                <Grid
                  className={classes.gridItem}
                  key={key}
                  item
                  xs={12}
                  sm={4}
                >
                  <Item item={items[key]} />
                </Grid>
              );
            })}
        </Grid>
      </div>
    );
};
export default withRouter(withFirebase(CategoryItemsList));
