export const EMAIL = "email";
export const USERNAME = "username";
export const ROLE = "role";
export const PASSWORD_ONE = "passwordOne";
export const PASSWORD_TWO = "passwordTwo";
export const PASSWORD = "password";
export const ERROR = "error";
export const ITEM_NAME = "itemName";
export const ITEM_DESCRIPTION = "itemDescription";

// Genders
export const MENS = "m";
export const WOMENS = "w";
export const BOYS = "b";
export const GIRLS = "g";
export const UNISEX = "u";

// States
export const RUNNING = "running";
export const PAUSED = "paused";
