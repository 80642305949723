import React, { useState, useEffect } from "react";
import { withFirebase } from "../../Firebase/context";
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import DropzoneContainer from "./DropzoneContainer";
import { makeStyles } from "@material-ui/core/styles";
import CustomAlert from "../../Alert/CustomAlert";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  gridItem: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  icon: {
    color: "#292f36ff",
  },
  add: {
    cursor: "pointer",
    textAlign: "center",
    padding: 2,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const AddItemForm = ({ category, firebase, handleFetchData }) => {
  const classes = useStyles();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [description, setDescription] = useState("");
  const [name, setName] = useState("");
  const [fileObject, setFileObject] = useState(null);
  const [severityType, setSeverityType] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    return () => {
      setInitialState();
    };
  }, []);

  const clearAlertState = () => {
    setSeverityType("");
    setMessage("");
    setError(null);
  };

  const setInitialState = () => {
    setError(null);
    setFileObject(null);
    setName("");
    setDescription("");
    setFileObject(null);
    setTimeout(() => clearAlertState(), 3000);
  };

  const handleDescription = (e) => {
    setDescription(e.target.value);
  };

  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleFileObject = (file) => {
    setFileObject(file[0]);
  };

  const handleSubmit = (e) => {
    setLoading(true);
    // const itemsRef = firebase.db.ref(`items`);
    const itemsRef = firebase.db.ref(`items/${category.id}/`);

    itemsRef
      .push()
      .then((snapshot) => {
        // const payloadData = {
        //   id: snapshot.key,
        //   categoryId: categoryData.id,
        //   itemName: name,
        //   itemDescription: description,
        // };
        const payloadData = {
          id: snapshot.key,
          itemName: name,
          itemDescription: description,
          categoryId: category.id,
        };
        if (typeof fileObject !== "undefined") {
          const itemStorageRef = firebase.storageRef.child(
            `items/${category.id}/${payloadData.id}.png`
          );
          itemStorageRef.put(fileObject).then((snapshot) => {
            const itemRef = firebase.db.ref(
              `items/${payloadData.categoryId}/${payloadData.id}`
            );
            itemRef.set(payloadData).then(() => {
              handleFetchData && handleFetchData();
              setInitialState();
              setMessage(`Item successfully added to ${category.name}!`);
              setSeverityType("success");
              setLoading(false);
            });
          });
        } else {
          handleFetchData && handleFetchData();
          setMessage(`Image required to add an item to ${category.name}!`);
          setSeverityType("warning");
          setLoading(false);
        }
      })
      .catch((err) => {
        setMessage(
          `Failed to add item to ${category.name}! If this persists contact your web developer.`
        );
        setSeverityType("error");
        setError(err);
        setLoading(false);
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setInitialState();
    setOpen(false);
  };

  const isInvalid =
    name === "" ||
    description.length >= 70 ||
    typeof fileObject === "undefined" ||
    fileObject === null;

  return (
    <div>
      {/* <div className={classes.add} onClick={handleClickOpen}>
        <span>
            <AddIcon variant="contained" className={classes.icon} />
            <strong>Add Item</strong>
        </span>
      </div> */}
      <Button onClick={handleClickOpen} variant="outlined" color="default">
        Add Item
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{category.name}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To add an item to the category: <b>{category.name}</b>, enter an
            item name, description, and image.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="add-item-name"
            label="Item Name"
            type="text"
            fullWidth
            value={name}
            onChange={handleName}
          />
          <TextField
            multiline
            rowsMax={2}
            margin="dense"
            id="add-item-description"
            label="Item Description"
            type="text"
            fullWidth
            value={description}
            onChange={handleDescription}
          />
          {!loading && (
            <DropzoneContainer handleFileObject={handleFileObject} />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button disabled={isInvalid} onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
        <DialogContent>
          {severityType.length > 0 && (
            <CustomAlert severityType={severityType} message={message} />
          )}
        </DialogContent>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>
    </div>
  );
};

export default withFirebase(AddItemForm);
