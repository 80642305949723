import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Typography from "material-ui/styles/typography";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const CustomAlert = ({ severityType, message }) => {
  const classes = useStyles();

  if (severityType === "success") {
    return (
      <Alert severity="success">
        <AlertTitle>Success</AlertTitle>
        {message}
      </Alert>
    );
  } else if (severityType === "error") {
    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        {message}
      </Alert>
    );
  } else if (severityType === "warning") {
    return (
      <Alert severity="warning">
        <AlertTitle>Warning</AlertTitle>
        {message}
      </Alert>
    );
  } else {
    return <div></div>;
  }
};

export default CustomAlert;
