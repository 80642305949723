import React, { useState, useEffect, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  Button
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { withFirebase } from "../../Firebase/context";
import categoriesList from "../../Constants/categoriesList";
import AddItemForm from "./AddItemForm";
import EditItems from "./EditItems";
import * as COLORS from "../../Constants/colors";
import { fade } from "@material-ui/core/styles/colorManipulator";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "192px"
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      height: "320px"
    }
  },
  dataGrid: {
    width: 530,
    height: 500
  },
  edit: {
    cursor: "pointer",
    textAlign: "center",
    padding: 2
  },
  icon: {
    color: "#292f36ff"
  },
  cardMedia: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "80px",
      objectFit: "scale-down"
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      height: 200,
      objectFit: "scale-down"
    }
  },
  cardMediaWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    padding: "6px"
  },
  cardContent: {
    padding: "6px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "80px",
      objectFit: "scale-down",
      textAlign: "center"
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      height: "100px",
      objectFit: "scale-down",
      textAlign: "center"
    }
  },
  card: {
    "&:hover": {
      backgroundColor: fade(COLORS.BLUE_BELL, 0.2)
    }
  },
  backButton: {
    position: "absolute",
    bottom: 0,
    left: 0,
    padding: "5px",
    margin: "5px"
  }
}));

const MenuGroupList = ({ category, firebase }) => {
  const classes = useStyles();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);

  const handleClickOpen = () => {
      console.log(category)
    setLoading(true);
    firebase.db
      .ref("items")
      .once("value", snapshot => {
        const filteredItems = [];
        snapshot.forEach(item => {
          const itemData = item.val();
          if (itemData.categoryId === category.id) {
            filteredItems.push(itemData);
          }
        });
        // setItems(filteredItems);

        const rowData = [];
        category.menuGroups.forEach(menuGroup =>
            menuGroup.subGroups.map(subGroup => {
              rowData.push({
                category: category.name,
                menuGroup: menuGroup.name,
                subGroup: subGroup
              });
            })
          );
          const rowMap = rowData.map(row => ({
            id: `${row.category}-${row.menuGroup}-${row.subGroup}`,
            col1: row.menuGroup.charAt(0).toUpperCase() + row.menuGroup.slice(1),
            col2: row.subGroup.charAt(0).toUpperCase() + row.subGroup.slice(1),
            col3: row,
            col4: row
          }));
          setRows(rowMap);
          setLoading(false);
          setOpen(true);
      })
      .catch(e => {
        setLoading(false);
        setError(e);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns = [
    { field: "id", hide: true },
    { field: "col1", headerName: "Item Name", width: 140 },
    { field: "col2", headerName: "Sub Group", width: 130 },
    {
      field: "col3",
      headerName: "Edit Item",
      width: 120,
      renderCell: ({ value }) => {
        return (
          <AddItemForm
            key={`add-${value.category}-${value.menuGroup}-${value.subGroup}`}
            data={value}
          />
        );
      }
    },
  ];

  return (
    <div>
      <Button onClick={handleClickOpen} color="default" variant="outlined">
        View
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Category: {category.name}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            If you wish to <b>add</b> a new <b>item</b> to a{" "}
            <b>sub-category group</b>, press <b>ADD ITEM</b>. Otherwise, if you
            wish to <b>delete</b> or <b>edit</b> the contents of an item that
            exists in a <b>sub-category</b>, press <b>VIEW</b>.
          </DialogContentText>
          <div className={classes.dataGrid}>
            <DataGrid
              hideFooterPagination={true}
              hideFooterSelectedRowCount={true}
              loading={loading}
              error={error && error.message}
              columns={columns}
              rows={rows}
            />
            <Grid item>
              <Button
                onClick={handleClose}
                className={classes.backButton}
                color="primary"
              >
                Back
              </Button>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default withFirebase(MenuGroupList);