import React, { useEffect, useState } from "react";
import {
  Camera,
  Download,
  Upload,
  ZoomIn,
  ZoomOut,
  XCircle
} from "react-feather";

const Item = ({ file, handleRemoveFile, setCurrentImagePreviewSrc}) => {
  const [error, setError] = useState(null);
  const imgSource = URL.createObjectURL(file);

  return (
    <div className="cell">
      <div className="cell-img">
        <img src={imgSource}></img>
      </div>

      <ZoomIn
        onClick={() => setCurrentImagePreviewSrc(imgSource)}
        className="zoom-in-icon"
      />
      <XCircle
        className="x-circle-icon"
        onClick={() => handleRemoveFile(file.name)}
      />
    </div>
  );
};

export default Item;
