import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { withFirebase } from "../../Firebase/context";
import { compose } from "recompose";
import { EMAIL, PASSWORD, ERROR } from "../../Constants/constants";
import { HOME } from "../../Constants/routes";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormGroup,
  FilledInput,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  OutlinedInput,
  TextField,
  MenuItem,
  Button,
  Box,
  Grid,
  Paper
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: 20
  },
  gridItem: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary
  }
}));

const SignInFormBase = ({ firebase, history }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const classes = useStyles();

  const onSubmit = e => {
    firebase
      .signInWithEmailAndPassword(email, password)
      .then(user => {
        setEmail("");
        setPassword("");
        history.push(HOME);
      })
      .catch(err => {
        setError(err);
        console.log(err);
      });
    e.preventDefault();
  };

  const onChange = e => {
    const value = e.target.value;
    const targetName = e.target.name;
    if (targetName === EMAIL) {
      setEmail(value);
    }
    if (targetName === PASSWORD) {
      setPassword(value);
    }
    if (targetName === ERROR) {
      setError(value);
    }
  };

  return (
    <Grid container direction="column" justify="center" alignItems="center">
      <form className={classes.root} onSubmit={onSubmit}>
        <FormControl>
          <Grid className={classes.gridItem} item s="true">
            <TextField
              name="email"
              value={email}
              onChange={onChange}
              type="text"
              label="Email Address"
              autoComplete="on"
            />
          </Grid>
          <Grid className={classes.gridItem} item s="true">
            <TextField
              name="password"
              value={password}
              onChange={onChange}
              type="password"
              label="Password"
              autoComplete="on"
            />
          </Grid>
          <Grid className={classes.gridItem} item s="true">
            <Button type="submit" variant="contained" color="primary">
              Sign In
            </Button>
          </Grid>

          {error && (
            <Grid item s="true">
              <p>{error.message}</p>
            </Grid>
          )}
        </FormControl>
      </form>
    </Grid>
  );
};

const SignInForm = compose(withRouter, withFirebase)(SignInFormBase);

export default SignInForm;
