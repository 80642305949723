import React, { useState, useEffect } from "react";
import { Link, withRouter, Redirect } from "react-router-dom";
import * as ROUTES from "../Constants/routes";
import * as ROLES from "../Constants/roles";
import AuthUserContext from "../Session/context";
import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import {
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
  Toolbar,
  useMediaQuery,
  Menu,
  MenuItem,
  Button,
  IconButton,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  Grid,
  Breadcrumbs,
  ExpandMoreIcon,
} from "@material-ui/core";
import { withFirebase } from "../Firebase/context";
import PersonIcon from "@material-ui/icons/Person";
import MenuIcon from "@material-ui/icons/Menu";
import HomeIcon from "@material-ui/icons/Home";
import InfoIcon from "@material-ui/icons/Info";
import NavigationBreadcrumb from "./NavigationBreadcrumb";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import RestoreIcon from "@material-ui/icons/Restore";
import FavoriteIcon from "@material-ui/icons/Favorite";
import LocationOnIcon from "@material-ui/icons/LocationOn";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  mobileRoot: {
    backgroundColor: "#292f36ff",
    color: "white",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: theme.spacing(2),
  },
  pad: {
    height: "64px",
  },
}));

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 100,
      width: "100%",
      backgroundColor: "#ed6a5aff",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const NavigationMenu = (props) => {
  const classes = useStyles();
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState(null);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const medium = useMediaQuery(theme.breakpoints.up("md"));
  const large = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    // setLoading(true);
    props.firebase.db
      .ref("/categories/")
      .once("value")
      .then((snapshot) => {
        const nextCategories = Object.entries(snapshot.val());
        setCategories(nextCategories);
      })
      .catch((e) => {
        // setLoading(false);
        setError(e);
      });
    // return () => {
    //   firebase.db.ref("/categories/").off();
    // };
  }, []);

  const handleOnClick = (event, newValue) => {
    props.history.push(newValue);
  };

  if (props.authUser) {
    // if (mobile) {
    //   return (
    //     <BottomNavigation
    //       // value={value}
    //       // onChange={(event, newValue) => {
    //       //   setValue(newValue);
    //       // }}
    //       showLabels
    //       className={classes.root}
    //     >
    //       <BottomNavigationAction
    //         label="Home"
    //         icon={<HomeIcon fontSize="small" />}
    //         onClick={e => handleOnClick(e, ROUTES.HOME)}
    //       />
    //       <BottomNavigationAction
    //         label="About"
    //         icon={<InfoIcon fontSize="small" />}
    //         onClick={e => handleOnClick(e, ROUTES.ABOUT)}
    //       />
    //       <BottomNavigationAction
    //         label="Admin"
    //         icon={<PersonIcon />}
    //         onClick={e => handleOnClick(e, ROUTES.ADMIN)}
    //       />
    //       <BottomNavigationAction
    //         label="Sign Out"
    //         icon={<LocationOnIcon />}
    //         onClick={e => handleOnClick(e, ROUTES.SIGN_OUT)}
    //       />
    //     </BottomNavigation>
    //   );
    // }
    return (
      <Breadcrumbs
        separator={<span style={{ color: "white" }}>/</span>}
        aria-label="breadcrumb"
      >
        <NavigationBreadcrumb
          breadcrumbType={"link"}
          navigationLabel={"Home"}
          icon={<HomeIcon fontSize="small" />}
          handleOnClick={(e) => handleOnClick(e, ROUTES.HOME)}
        />
        <NavigationBreadcrumb
          breadcrumbType={"link"}
          navigationLabel={"Contact Us"}
          icon={<InfoIcon fontSize="small" />}
          handleOnClick={(e) => handleOnClick(e, ROUTES.CONTACT_US)}
        />
        {/* <NavigationBreadcrumb
            breadcrumbType={"menulist"}
            navigationLabel={"Catalog"}
            icon={<MenuBookIcon fontSize="small" />}
            categories={categories}
          /> */}
        <NavigationBreadcrumb
          breadcrumbType={"link"}
          navigationLabel={"Catalog"}
          handleOnClick={(e) => handleOnClick(e, ROUTES.CATALOG)}
        />
        <NavigationBreadcrumb
          breadcrumbType={"link"}
          navigationLabel={"Admin"}
          handleOnClick={(e) => handleOnClick(e, ROUTES.ADMIN)}
        />
        <NavigationBreadcrumb
          breadcrumbType={"link"}
          navigationLabel={"Sign Out"}
          handleOnClick={props.firebase.signOut}
        />
      </Breadcrumbs>
    );
  }

  if (!props.authUser) {
    // if (mobile) {
    //   return (
    //     <BottomNavigation
    //       // value={value}
    //       // onChange={(event, newValue) => {
    //       //   setValue(newValue);
    //       // }}
    //       showLabels
    //       className={classes.root}
    //     >
    //       <BottomNavigationAction
    //         label="Home"
    //         icon={<HomeIcon fontSize="small" />}
    //         onClick={e => handleOnClick(e, ROUTES.HOME)}
    //       />
    //       <BottomNavigationAction
    //         label="About"
    //         icon={<InfoIcon fontSize="small" />}
    //         onClick={e => handleOnClick(e, ROUTES.ABOUT)}
    //       />
    //       <BottomNavigationAction
    //         label="Sign In"
    //         onClick={e => handleOnClick(e, ROUTES.SIGN_IN)}
    //       />
    //     </BottomNavigation>
    //   );
    // } else {
    return (
      <Breadcrumbs
        separator={<span style={{ color: "white" }}>/</span>}
        aria-label="breadcrumb"
      >
        <NavigationBreadcrumb
          breadcrumbType={"link"}
          navigationLabel={"Home"}
          icon={<HomeIcon fontSize="small" />}
          handleOnClick={(e) => handleOnClick(e, ROUTES.HOME)}
        />
        <NavigationBreadcrumb
          breadcrumbType={"link"}
          navigationLabel={"Contact Us"}
          icon={<InfoIcon fontSize="small" />}
          handleOnClick={(e) => handleOnClick(e, ROUTES.CONTACT_US)}
        />
        {/* <NavigationBreadcrumb
            breadcrumbType={"menulist"}
            navigationLabel={"Catalog"}
            icon={<MenuBookIcon fontSize="small" />}
            categories={categories}
          /> */}
        <NavigationBreadcrumb
          breadcrumbType={"link"}
          navigationLabel={"Catalog"}
          handleOnClick={(e) => handleOnClick(e, ROUTES.CATALOG)}
        />
        <NavigationBreadcrumb
          breadcrumbType={"link"}
          navigationLabel={"Sign In"}
          handleOnClick={(e) => handleOnClick(e, ROUTES.SIGN_IN)}
        />
      </Breadcrumbs>
    );
  }
};

export default withFirebase(NavigationMenu);
