import React, { useState, useEffect } from "react";
import { withFirebase } from "../Firebase/context";
import * as CONSTANTS from "../Constants/constants";
import * as COLORS from "../Constants/colors";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Card,
  CardActionArea,
  CardContent,
  CardMedia
} from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Loading from "../Loading/Loading";
const LoadingSpinner = require("../Loading/assets/spinner.svg");

const useStyles = makeStyles(theme => {
  return {
    root: {
      flexGrow: 1
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    paper: {
      [theme.breakpoints.down("sm")]: {
        maxwidth: "100%"
      },
      [theme.breakpoints.up("sm")]: {
        height: 600,
        width: 800
      },
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3)
    },
    image: {
      height: "100%",
      width: "100%",
      objectFit: "scale-down"
    },
    cardMedia: {
      width: "100%",
      height: 245,
      objectFit: "scale-down"
    },
    cardContent: {
      textAlign: "center",
      height: 125
    },
    cardMediaWrapper: {
      height: 245
    }
  };
});

const Item = ({ item, firebase }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [error, setError] = useState(null);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setLoading(true);
    firebase.storage
      .ref(
        `items/${item.categoryId}/${item.id}.png` ||
          `items/${item.categoryId}/${item.id}.jpg` ||
          `items/${item.categoryId}/${item.id}.jpeg`
      )
      .getDownloadURL()
      .then(url => {
        setImageUrl(url);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <Card className={classes.root} key={"item-loading"}>
        <CardActionArea>
          <CardMedia
            component="img"
            className={classes.cardMedia}
            alt={"Loading..."}
            src={LoadingSpinner}
            title={"Loading..."}
          />
          <CardContent className={classes.cardContent}>
            <Typography gutterBottom variant="h5" component="h2">
              Loading...
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  } else if (error) {
    return (
      <Card className={classes.root} key={"item-loading-error"}>
        <CardActionArea>
          <CardMedia
            component="img"
            className={classes.cardMedia}
            alt={"error"}
            src={LoadingSpinner}
            title={"Error loading item"}
          />
          <CardContent className={classes.cardContent}>
            <Typography gutterBottom variant="h5" component="h2">
              Error loading item
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  } else {
    return (
      <Card className={classes.root} key={item[0]}>
        <CardActionArea onClick={handleOpen}>
          <CardMedia
            component="img"
            className={classes.cardMedia}
            alt={"item image"}
            src={imageUrl}
            title={item.itemName}
          />
          <CardContent className={classes.cardContent}>
            <Typography gutterBottom variant="h5" component="h2">
              {item.itemName}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {item.itemDescription}
            </Typography>
          </CardContent>
        </CardActionArea>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <img className={classes.image} src={imageUrl}></img>
            </div>
          </Fade>
        </Modal>
      </Card>
    );
  }
};

export default withFirebase(Item);
