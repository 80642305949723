import React, { createRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { AddBox, PersonAdd } from "@material-ui/icons/";
import MenuIcon from "@material-ui/icons/Menu";
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import * as COLORS from "../Constants/colors";
import { fade } from "@material-ui/core/styles/colorManipulator";

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  icon: {
    height: "100%",
    width: "100%",
    color: "#292f36ff",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: fade(COLORS.BLUE_BELL, 0.2)
    }
  }
}));

const CustomIconButton = ({ optionType, handleOpen }) => {
  const classes = useStyles();
  if (optionType === "createUser") {
    return <PersonAdd className={classes.icon} onClick={handleOpen} />;
  }
  if (optionType === "editCatalog") {
    return <MenuIcon className={classes.icon} onClick={handleOpen} />;
  } else {
    return (
      <button type="button" onClick={handleOpen}>
        <h2>Click</h2>
      </button>
    );
  }
};

const TransitionsModal = ({ optionType, children }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <CustomIconButton optionType={optionType} handleOpen={handleOpen} />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>{children}</div>
        </Fade>
      </Modal>
    </div>
  );
};

export default TransitionsModal;
