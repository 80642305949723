import React, { useState, useEffect, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InfoIcon from "@material-ui/icons/Info";
import {
  GridList,
  GridListTileBar,
  GridListTile,
  ListSubheader,
  IconButton,
  Button,
  MenuList,
  MenuItem,
} from "@material-ui/core";
import { Link, withRouter, Redirect } from "react-router-dom";
import { DataGrid } from "@material-ui/data-grid";
import { withFirebase } from "../Firebase/context";
import * as ROUTES from "../Constants/routes";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 500,
    height: 450,
  },
  edit: {
    cursor: "pointer",
    textAlign: "center",
    padding: 2,
  },
  icon: {
    color: "#292f36ff",
  },
}));

const CategoryMenuList = ({ categories, handleClose, history }) => {
  const handleOnClick = (e, category) => {
    history.push(`${ROUTES.CATALOG}/${category[0]}`);
    handleClose(e);
  };

  return categories
    .filter((category) => !!category[1].items)
    .map((category) => (
      <MenuItem key={category[0]} onClick={(e) => handleOnClick(e, category)}>
        {category[1].name}
      </MenuItem>
    ));
};

export default withRouter(withFirebase(CategoryMenuList));
