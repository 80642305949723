import React, { useEffect } from "react";
import withAuthorization from "../Session/withAuthorization";
import { withFirebase } from "../Firebase/context";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch,
  Redirect,
} from "react-router-dom";
import * as ROUTES from "../Constants/routes";
import * as ROLES from "../Constants/roles";
import PerfectScrollbar from "react-perfect-scrollbar";
import UploadPhotoContainer from "./CatalogEditor/UploadPhotoContainer";
import CreateUserForm from "./CreateUserForm";
import {
  Grid,
  GridList,
  GridListTile,
  GridListTileBar,
  IconButton,
  Container,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// import EditItems from "./EditItems";
import CategoryList from "./CatalogEditor/CategoryList";

/**
 * TODO: Set default props for error handling and testing.
 */
const useStyles = makeStyles((theme) => ({
  gridItem: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  root: {
    flexGrow: 1,
    padding: 20,
  },
  gridList: {
    width: 500,
    height: 450,
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)",
  },
}));

const AdminPage = ({ firebase, history, match: path }) => {
  const classes = useStyles();

  return (
    <Container className={classes.root} maxWidth="md">
      <Grid container spacing={3}>
        <Grid key={"catalog-editor"} item xs={12} sm={6}>
          <CategoryList />
        </Grid>
        <Grid key={"create-user"} item xs={12} sm={6}>
          <CreateUserForm />
        </Grid>
      </Grid>
    </Container>
  );
};
const condition = (authUser) => authUser.role === ROLES.ADMIN;

export default withAuthorization(condition)(AdminPage);
