export const LANDING = "/";
export const SIGN_UP = "/signup";
export const SIGN_IN = "/signin";
export const SIGN_OUT = "/signout";
export const HOME = "/home";
export const CONTACT_US = "/contact-us";
export const ACCOUNT = "/account";
export const PASSWORD_FORGET = "/pw-forget";
export const ORDER_FORM = "/order-form";
export const ERROR = "/error";
export const ERROR404 = "/error404";

/**
 * AdminPage Routes
 * Any nested route for AdminPage options goes here
 */
export const ADMIN = "/admin";
export const ADMIN_CREATE_USER = "admin/create-user";
export const ADMIN_CATALOG_EDITOR = "admin/catalog-editor";
export const ADMIN_DASHBOARD = "admin/dashboard";

/**
 * Catalog Routes
 * Any nested route for CatelogPage goes here
 */
export const CATALOG = "/catalog";
