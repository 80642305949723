import React, { useState } from "react";
import { Link, withRouter, Redirect } from "react-router-dom";
import * as ROUTES from "../Constants/routes";
import * as ROLES from "../Constants/roles";
import * as COLORS from "../Constants/colors";
import AuthUserContext from "../Session/context";
import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import {
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
  Toolbar,
  useMediaQuery,
  Menu,
  MenuItem,
  Button,
  IconButton,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  Grid,
} from "@material-ui/core";
import { withFirebase } from "../Firebase/context";
import MenuIcon from "@material-ui/icons/Menu";
import NavigationMenu from "./NavigationMenu";

const useStyles = makeStyles((theme) => ({
  root: {
    color: "white",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
    bottom: 0,
  },
  mobileRoot: {
    color: "white",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: COLORS.GUN_METAL,
    padding: theme.spacing(1),
  },
}));

// Higher order components for changing material-ui styles
const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: "#fff",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: "1rem",
    "&:focus": {
      opacity: 1,
    },
  },
}))((props) => {
  return <Tab disableRipple {...props} />;
});

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 100,
      width: "100%",
      backgroundColor: COLORS.GREEN_PANTONE,
    },
  },
})((props) => (
  <Tabs
    variant={"fullWidth"}
    {...props}
    TabIndicatorProps={{ children: <span /> }}
  />
));

const Navigation = ({ history, location, match, firebase }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const medium = useMediaQuery(theme.breakpoints.up("md"));
  const large = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <AuthUserContext.Consumer>
      {(authUser) => {
        return (
          <AppBar position="fixed" className={classes.appBar}>
            <div className={mobile ? classes.mobileRoot : classes.root}>
              {authUser ? (
                <NavigationAuth
                  authUser={authUser}
                  history={history}
                  firebase={firebase}
                />
              ) : (
                <NavigationNonAuth history={history} />
              )}
            </div>
          </AppBar>
        );
      }}
    </AuthUserContext.Consumer>
  );
};

const NavigationAuth = ({ authUser, history, firebase: { signOut } }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("xs"));

  // const medium = useMediaQuery(theme.breakpoints.up("md"));
  // const large = useMediaQuery(theme.breakpoints.up("lg"));

  const handleTabChange = (event, newValue) => {
    authUser && history.push(newValue);
  };

  const handleMenuClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuPress = (e, newValue) => {
    newValue && authUser && history.push(newValue);
    handleClose();
  };

  if (mobile) {
    return (
      <div>
        <IconButton
          edge="end"
          color="inherit"
          aria-label="menu"
          onClick={handleMenuClick}
        >
          <MenuIcon fontSize="large" variant="contained" color="inherit" />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={(e) => handleMenuPress(e, ROUTES.HOME)}>
            Home
          </MenuItem>
          <MenuItem onClick={(e) => handleMenuPress(e, ROUTES.CATALOG)}>
            Catalog
          </MenuItem>
          <MenuItem onClick={(e) => handleMenuPress(e, ROUTES.CONTACT_US)}>
            Contact Us
          </MenuItem>
          {authUser.role.includes(ROLES.ADMIN) && (
            <MenuItem onClick={(e) => handleMenuPress(e, ROUTES.ADMIN)}>
              Admin
            </MenuItem>
          )}
          <MenuItem onClick={signOut}>Sign Out</MenuItem>
        </Menu>
      </div>
    );
  } else {
    return (
      <Grid spacing={3} container>
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          <StyledTabs
            value={history.location.pathname}
            onChange={handleTabChange}
          >
            <StyledTab label="Home" value={ROUTES.HOME} />
            <StyledTab
              label="Catalog"
              value={
                (history.location.pathname.includes(ROUTES.CATALOG) &&
                  history.location.pathname) ||
                ROUTES.CATALOG
              }
            />
            <StyledTab label="Contact Us" value={ROUTES.CONTACT_US} />
            {/* <StyledTab label="Account" value={ROUTES.ACCOUNT} /> */}
            {authUser.role.includes(ROLES.ADMIN) && (
              <StyledTab label="Admin" value={ROUTES.ADMIN} />
            )}
            <StyledTab label="Sign Out" onClick={signOut} />
          </StyledTabs>
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
    );
  }
};

const NavigationNonAuth = ({ history }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("xs"));
  // const medium = useMediaQuery(theme.breakpoints.up("md"));
  // const large = useMediaQuery(theme.breakpoints.up("lg"));

  const handleTabChange = (event, newValue) => {
    history.push(newValue);
  };

  const handleMenuClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuPress = (e, newValue) => {
    newValue && history.push(newValue);
    handleClose();
  };

  if (mobile) {
    return (
      <div>
        <IconButton
          edge="end"
          color="inherit"
          aria-label="menu"
          onClick={handleMenuClick}
        >
          <MenuIcon fontSize="large" variant="contained" color="inherit" />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={(e) => handleMenuPress(e, ROUTES.HOME)}>
            Home
          </MenuItem>
          <MenuItem onClick={(e) => handleMenuPress(e, ROUTES.CATALOG)}>
            Catalog
          </MenuItem>
          <MenuItem onClick={(e) => handleMenuPress(e, ROUTES.CONTACT_US)}>
            Contact Us
          </MenuItem>
          <MenuItem onClick={(e) => handleMenuPress(e, ROUTES.SIGN_IN)}>
            Sign In
          </MenuItem>
        </Menu>
      </div>
    );
  } else {
    return (
      <Grid spacing={3} container>
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          <StyledTabs
            value={history.location.pathname}
            onChange={handleTabChange}
          >
            <StyledTab label="Home" value={ROUTES.HOME} />
            <StyledTab
              label="Catalog"
              value={
                (history.location.pathname.includes(ROUTES.CATALOG) &&
                  history.location.pathname) ||
                ROUTES.CATALOG
              }
            />
            <StyledTab label="Contact Us" value={ROUTES.CONTACT_US} />
            <StyledTab label="Sign In" value={ROUTES.SIGN_IN} />
          </StyledTabs>
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
    );
  }
};

export default withRouter(withFirebase(Navigation));
