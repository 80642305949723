import React, { Component } from "react";
import { UploadCloud } from "react-feather";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

class DragAndDrop extends Component {
  state = {
    drag: false,
  };
  dropRef = React.createRef();
  handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ drag: true });
    }
  };
  handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter--;
    if (this.dragCounter === 0) {
      this.setState({ drag: false });
    }
  };
  handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ drag: false });
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      this.props.handleDrop(e.dataTransfer.files);
      e.dataTransfer.clearData();
      this.dragCounter = 0;
    }
  };
  componentDidMount() {
    let div = this.dropRef.current;
    div.addEventListener("dragenter", this.handleDragIn);
    div.addEventListener("dragleave", this.handleDragOut);
    div.addEventListener("dragover", this.handleDrag);
    div.addEventListener("drop", this.handleDrop);
  }
  componentWillUnmount() {
    let div = this.dropRef.current;
    div.removeEventListener("dragenter", this.handleDragIn);
    div.removeEventListener("dragleave", this.handleDragOut);
    div.removeEventListener("dragover", this.handleDrag);
    div.removeEventListener("drop", this.handleDrop);
  }
  render() {
    const { children, handleChooseFiles, handleSubmit } = this.props;
    const { drag } = this.state;
    return (
      <div className="drag-and-drop" ref={this.dropRef}>
        {drag && (
          <div className="dragging">
            <div className="dragging-center">
              <div>
                <p>Drop Photos Here</p>
              </div>
            </div>
          </div>
        )}
        <>
          <UploadCloud className="upload-cloud-icon" />
          <PerfectScrollbar>
            <div className="grid">{children}</div>
          </PerfectScrollbar>

          <div className="upload-submit">
            <h1>Drag and Drop Image Files or</h1>
            <form onSubmit={handleSubmit} className="upload-submit-form">
              <input
                onChange={handleChooseFiles}
                type="file"
                name="filefield"
                multiple="multiple"
              />
              <button type="submit">Submit</button>
            </form>
          </div>
        </>
      </div>
    );
  }
}
export default DragAndDrop;
